.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nav-logo {
  width: 2rem;
  margin-right: .3rem;
}

.App-header {
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}


.none-list {
  list-style-type: none;
}
.trans-button { 
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;

}

.code-block {
  background: #f5f5f5;
}

.nav-bot {
  padding-bottom: 8px;
}
.schedule-col {
  padding-bottom: 10px;
}

#page-container {
  position: relative;
  min-height: 95vh;
}

#content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}